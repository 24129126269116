.supy-input--compact {
  --ig-size: 2rem;

  &:not(.supy-textarea) {
    .igx-input-group {
      &__bundle {
        height: 2rem !important;

        &-main {
          padding: 0 !important;
        }
      }

      igx-prefix,
      [igxPrefix] {
        margin-inline-start: 0;
      }

      &--focused {
        igx-prefix {
          margin-inline-start: 0;
        }
      }

      &--border {
        &.igx-input-group--focused {
          [igxPrefix] {
            &:first-child {
              margin-inline-start: 0;
            }
          }

          .igx-input-group__bundle-start {
            border-block-end-width: 0.0625rem;
            border-block-start-width: 0.0625rem;
            border-inline-start-width: 0.0625rem;
          }
        }
      }

      &__input {
        font-size: 0.875rem;
        transform: none;
      }
    }
  }
}
