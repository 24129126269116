@use '@infragistics/igniteui-angular/theming' as *;
@use '@ag-grid-community/styles' as ag;
@use '@angular/material' as mat;
@use 'angular' as ng;

@import 'modern-normalize/modern-normalize';
@import '@fontsource/material-icons/index.css';
@import '@simonwep/pickr/dist/themes/monolith.min.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'splash-screen/components/splash-screen/splash-screen.component';
@import 'core';
@import 'igx';
@import 'global';
@import 'quill/dist/quill.snow.css';

@include supy-default-font($esbuild: true);
@include ag.grid-styles(
  $global-params: (
    theme: alpine,
  )
);

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
  )
);

@include mat.core();
@include mat.color-variants-backwards-compatibility($theme);
@include ng.override();

.reduce-layout-height {
  $banner-height: 3.125rem;

  supy-layout {
    height: calc(100vh - $banner-height);
  }

  supy-dialog {
    .igx-dialog__window {
      margin-block-start: $banner-height;
      max-height: calc(100vh - $banner-height);
    }
  }
}
